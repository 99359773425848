<template>
  <v-card>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-card-title
      class="card-title v-card-scroll"
      color="black"
    >
      {{ $t('NavigationSideBar.layer') }}
      <v-spacer />
      <v-icon
        v-if="mobileBreakpoints()"
        color="red"
        size="28"
        @click="$router.push('/')"
      >
        mdi-close
      </v-icon>
    </v-card-title>
    <v-card-text class="pt-0">
      <v-data-table
        id="services-table"
        :headers="headers"
        :items="global_layers.customerLayers"
        :search="search"
        :loading="loading"
        class="elevation-1 mt-4"
        :footer-props="{
          itemsPerPageAllText: $t('Table.all'),
          itemsPerPageText: $t('Table.rowsPerPage'),
          pageText: `{0}-{1} ${$t('Table.of')} {2}`
        }"
        :no-data-text="$t('Table.noData')"
      >
        <template v-slot:top>
          <div class="container">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('AlertExPost.search')"
              :style="mobileBreakpoints() ? 'width: 100%' : 'max-width: 300px'"
              single-line
              hide-details
            />
            <v-spacer v-if="!mobileBreakpoints()" />
            <v-btn
              color="#0da344"
              style="color:white"
              small
              :style="mobileBreakpoints() ? 'width: 100%' : ''"
              @click="createButtonEvent"
            >
              {{ $t('CustomerLayer.new') }}
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editButtonEvent(item)"
          >
            mdi-pencil
          </v-icon>
          <delete
            :id="item.name"
            @deleted="deleteButtonEvent(item)"
          />
        </template>
        <template v-slot:no-data>
          <span color="primary">No data to show you</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    delete: () => import('@/components/utils/Delete')
  },
  data() {
    return {
      search: '',
      loading: false,
      overlay: false,
      tableItems: [],
      headers: [
        {
          text: `${this.$t('CustomerLayer.name')}`,
          value: 'layer_name'
        },
        {
          text: `${this.$t('CustomerLayer.description')}`,
          value: 'layer_description'
        },
        {
          text: `${this.$t('CustomerLayer.action')}`,
          value: 'actions',
          sortable: false,
          align: 'center',
          width: 130
        }
      ]
    };
  },
  computed: {
    ...mapState(['global_layers', 'app_user'])
  },
  async mounted() {
    this.loading = true;

    await this.storeCustomerLayers(this.app_user.crm_id);

    this.loading = false;
  },
  methods: {
    ...mapActions(['storeCustomerLayers', 'deleteCustomerLayerAction']),
    async deleteButtonEvent(item) {
      this.overlay = true;

      const data = {
        global_layer_id: item.global_layer_id,
        crm_id: this.app_user.crm_id
      };

      await this.deleteCustomerLayerAction(data);
      this.storeCustomerLayers(this.app_user.crm_id);

      this.overlay = false;
    },
    editButtonEvent(item) {
      this.$emit('editLayerEvent', item);
    },
    createButtonEvent() {
      this.$emit('createLayerEvent');
    },
    mobileBreakpoints() {
      return this.$vuetify.breakpoint.name === 'xs';
    }
  }
};
</script>

<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
.card-title {
  color: #0da344;
  background-color: #f4f3f3;
  font-size: 22px;
}
.container {
  padding: 12px;
  display: flex;
  flex-wrap: wrap-reverse;
}
</style>
